<template>
<div class="page">
  <el-row>
    <el-col :span="13">
      <el-form label-position="top">
        <el-form-item label="标题">
          <el-input v-model="articleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="封面">
          <el-button v-show="!articleForm.pic" icon="el-icon-plus" plain style="width: 100px; height: 100px;border-radius: 10px" @click="showSelecter = true"/>
          <el-image v-show="articleForm.pic" :src="articleForm.pic" style="width: 100px; height: 100px;border-radius: 10px;cursor: pointer" @click="showSelecter = true"/>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="articleForm.category_id">
            <el-option
                v-for="item in categoryOption"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签">
          <el-input v-model="articleForm.slug"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="articleForm.state">
            <el-radio-button :label="0">草稿</el-radio-button>
            <el-radio-button :label="1">发布</el-radio-button>
          </el-radio-group>
        </el-form-item>
          <el-form-item label="SEO关键词">
              <el-input v-model="articleForm.seo_keyword"></el-input>
          </el-form-item>
          <el-form-item label="SEO描述">
              <el-input type="textarea" v-model="articleForm.seo_descr" :rows="6"></el-input>
          </el-form-item>
        <el-form-item label="内容">
          <tinymce :height="600" v-model="articleForm.content" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="release">发布</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
  <fileSelect :visible.sync="showSelecter" title="选择封面图片" state="image" @confirm="showSelecterSubmit"></fileSelect>
</div>
</template>

<script>
import fileSelect  from "@/views/components/fileSelect";
import {
    apiGetBLOG,
    apiCreateOrUpdateBLOG, apiGetBLOGCategory
} from "@/request/api"
import { mapActions } from 'vuex'
export default {
  name: "release",
  data() {
    return {
      showSelecter: false,
      articleForm:{
        title:'',
        content:'',
        pic:'',
        slug:'',
        category_id:'',
        state:0,
          seo_keyword:'',
          seo_descr:''
      },
      categoryOption:[]
    }
  },
  components: {
    fileSelect
  },
  created() {
    this.getCategories()
    if(this.$route.query.id){
      this.getArticle()
    }
  },
  methods:{
    ...mapActions({
      delVisitedRoute: 'tabs/delVisitedRoute',
    }),
    showSelecterSubmit(data){
      this.articleForm.pic = data[0].origin
    },
    getCategories(){
        apiGetBLOGCategory().then(res => {
        if(res.code == 200){
          this.categoryOption = res.data
        }
      })
    },
    release(){
        apiCreateOrUpdateBLOG(this.articleForm).then(res => {
        if(res.code == 200){
          this.delVisitedRoute(this.$route.path)
          this.$router.replace({path:'/blog/BlogList'})
        }
      })
    },
    getArticle(){
        apiGetBLOG({id:this.$route.query.id}).then(res => {
        console.log(res)
        if(res.code == 200){
          this.articleForm = res.data
        }
      })
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
}
</style>
